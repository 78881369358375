// @ts-nocheck
/*
 * Copyright (C) 2023 - present Instructure, Inc.
 *
 * This file is part of Canvas.
 *
 * Canvas is free software: you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License as published by the Free
 * Software Foundation, version 3 of the License.
 *
 * Canvas is distributed in the hope that it will be useful, but WITHOUT ANY
 * WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 * A PARTICULAR PURPOSE. See the GNU Affero General Public License for more
 * details.
 *
 * You should have received a copy of the GNU Affero General Public License along
 * with this program. If not, see <http://www.gnu.org/licenses/>.
 */

const hasProp = {}.hasOwnProperty

export const extend = function (child: Function, parent: Function) {
  for (const key in parent) {
    if (hasProp.call(parent, key)) child[key] = parent[key]
  }
  function ctor() {
    // @ts-expect-error
    this.constructor = child
  }
  ctor.prototype = parent.prototype
  // eslint-disable-next-line new-cap
  child.prototype = new ctor()
  // @ts-expect-error
  child.__super__ = parent.prototype
  return child
}
